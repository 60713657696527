import { memo, useMemo, createRef, useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import uniqBy from 'lodash/uniqBy';
import { getHexColor, getProperty, getPropertyValueFromConfiguration } from '@src/utils';
import * as Styles from './colors.styled';

const ColorItem = dynamic(() => import('../../ColorItem'), { ssr: true });

const Colors = ({ model, index, configs, handleSelectTarget }) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const { locale } = useRouter();
    const slider = useRef();
    const uniqConfigs = useMemo(() => uniqBy(configs, `configuration_property.${getProperty(configs[0], 'Item Color', true)}.property_value_id`), [configs]);

    const refs = uniqConfigs.map(() => createRef());

    function handleClick(i, config) {
        const ref = refs[i]?.current;
        if (ref) {
            ref.parentNode.scrollLeft = ref.offsetLeft - 60;
        }

        setSlideIndex(i);
        handleSelectTarget(config);
    }

    useEffect(() => {
        slider.current.scrollLeft = refs[index].current.offsetLeft - 60;
        setSlideIndex(index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleNavigation(direction) {
        return () => {
            const mappingDirection = {
                prev: slideIndex - 1,
                next: slideIndex + 1,
            };

            const maxScroll = slider.current.scrollWidth - slider.current.clientWidth;

            if (direction === 'prev' && slideIndex === 0) {
                handleClick(0, uniqConfigs[0]);
                return false;
            }

            if (mappingDirection[direction] <= uniqConfigs.length - 1) {
                handleClick(mappingDirection[direction], uniqConfigs[mappingDirection[direction]]);
            }

            if (direction === 'next' && slider.current.scrollLeft === maxScroll) {
                return false;
            }

            return true;
        };
    }

    const hasNavigation = uniqConfigs.length >= 5;

    return (
        <Styles.Wrapper>
            { hasNavigation && <button type="button" className={`prevButtonColors prevButtonColors-${model} i-chevron-left`} aria-label="Previous" onClick={handleNavigation('prev')} /> }

            <Styles.Slider ref={slider} className={uniqConfigs.length >= 5 ? '' : '__centered'}>
                {
                    uniqConfigs.map((config, i) => {
                        const colors = getHexColor(config);

                        return (
                            <button
                                key={config.id}
                                ref={refs[i]}
                                title={getPropertyValueFromConfiguration(config, 'Item Color', 'name', locale)}
                                onClick={() => handleClick(i, config)}
                                type="button"
                            >
                                <ColorItem {...colors} className={slideIndex === i ? '__selected' : ''} />
                            </button>
                        );
                    })
                }
            </Styles.Slider>

            { hasNavigation && <button type="button" className={`nextButtonColors nextButtonColors-${model} i-chevron-right`} aria-label="Next" onClick={handleNavigation('next')} /> }
        </Styles.Wrapper>
    );
};

export default memo(Colors);
