import styled from 'styled-components';
import { size } from 'polished';
import { BLUE } from '../../assets/styles/settings.styled';

const StyledColor = styled.div`
    ${size('18px', '18px')}
    display: inline-block;
    vertical-align: top;
    border-radius: 50em;
    border: 1px solid #ccc;
    cursor: pointer;
    margin-bottom: 5px;
    transition: border-color .2s;
    opacity: .9;

    &:hover {
        border-color: ${BLUE};
    }

    &:before {
        content: '';
        display: block;
        ${size('100%', '100%')}
        border-radius: 50em;
        border: 3px solid #FFFFFF;
    }

    &.__selected {
        opacity: 1;
        border: 2px solid ${BLUE};
    }
`;

export default StyledColor;
