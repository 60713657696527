import styled from 'styled-components';
import StyledColor from '../../ColorItem/color.styled';

export const StyledDot = styled.div`
    width: 16px;
    height: 16px;
    margin: 5px auto 0;
    border-radius: 50%;
    background: ${({ color }) => (color || 'black')};
    box-shadow: 0 0 0 1px white;
    border: 3px solid white;
    cursor: pointer;
`;

export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    ${StyledColor} {
        display: block;
        margin-top: 5px;
    }

    > button {
        display: block;
        text-align: center;
        color: var(--light);
        font-size: 25px;
        transition: color .2s ease-in;

        &:hover {
            color: var(--primary);
        }
    }
`;

export const Slider = styled.section`
    width: 130px;
    display: flex;
    overflow-x: scroll;
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    gap: 0;

    &.__centered {
        justify-content: center;
        width: fit-content;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    > button {
        scroll-snap-align: center;
        flex-shrink: 0;
        transform: translateZ(0);
        display: inline-block;
        width: 26px;
    }
`;
